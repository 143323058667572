import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import api from '../sharedComponents/api';

// Material-UI
import {
  Container,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button
} from '@mui/material';

const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    number_of_persons: '',
    message: '',
    ishandycap: 'No',
    haswbs: 'No',
    wbs_expire_date: '',
    wbs_number_of_rooms: '',
    wbs_type: 'WBS140',
    is_admin: 'No',
    email_confirmed: 'No'
  });

  useEffect(() => {
    async function fetchUser() {
      try {
        const resp = await api.get(`/admin/users/${id}`);
        // resp.data = { first_name, last_name, ... is_admin: true, ... }
        const user = resp.data;
        setFormData({
          ...user,
          ishandycap: user.ishandycap ? 'Yes' : 'No',
          haswbs: user.haswbs ? 'Yes' : 'No',
          wbs_expire_date: user.wbs_expire_date
            ? user.wbs_expire_date.split('T')[0]
            : '',
          is_admin: user.is_admin ? 'Yes' : 'No',
          email_confirmed: user.email_confirmed ? 'Yes' : 'No'
        });
      } catch (err) {
        console.error('Fehler beim Laden des Nutzers:', err);
      }
    }
    fetchUser();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'radio' ? value : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...formData,
      ishandycap: formData.ishandycap === 'Yes',
      haswbs: formData.haswbs === 'Yes',
      is_admin: formData.is_admin === 'Yes',
      email_confirmed: formData.email_confirmed === 'Yes'
    };
    if (!payload.wbs_expire_date) {
      payload.wbs_expire_date = null;
    }
    try {
      await api.put(`/admin/users/${id}`, payload);
      alert('Benutzer aktualisiert.');
      navigate('/admin-dashboard');
    } catch (err) {
      console.error('Fehler beim Aktualisieren:', err);
    }
  };

  // Navigation zu anderen Seiten
  const goToApplications = () => navigate(`/admin/user/${id}/applications`);
  const goToPreferences = () => navigate(`/admin/user/${id}/preferences`);
  const goToPayments = () => navigate(`/admin/user/${id}/user-payments`);

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Benutzer bearbeiten
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            label="Vorname"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nachname"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="E-Mail"
            name="email"
            value={formData.email}
            InputProps={{ readOnly: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Telefon"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Personenanzahl"
            name="number_of_persons"
            type="number"
            value={formData.number_of_persons}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nachricht"
            name="message"
            multiline
            rows={3}
            value={formData.message}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Handicap */}
          <Typography>Handicap?</Typography>
          <RadioGroup row name="ishandycap" value={formData.ishandycap} onChange={handleChange}>
            <FormControlLabel value="Yes" control={<Radio />} label="Ja" />
            <FormControlLabel value="No" control={<Radio />} label="Nein" />
          </RadioGroup>

          {/* WBS */}
          <Typography>WBS vorhanden?</Typography>
          <RadioGroup row name="haswbs" value={formData.haswbs} onChange={handleChange}>
            <FormControlLabel value="Yes" control={<Radio />} label="Ja" />
            <FormControlLabel value="No" control={<Radio />} label="Nein" />
          </RadioGroup>

          {formData.haswbs === 'Yes' && (
            <>
              <TextField
                label="WBS gültig bis"
                type="date"
                name="wbs_expire_date"
                value={formData.wbs_expire_date}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="WBS Zimmeranzahl"
                name="wbs_number_of_rooms"
                type="number"
                value={formData.wbs_number_of_rooms}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="WBS-Typ"
                name="wbs_type"
                value={formData.wbs_type}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </>
          )}

          {/* is_admin */}
          <Typography>Admin?</Typography>
          <RadioGroup row name="is_admin" value={formData.is_admin} onChange={handleChange}>
            <FormControlLabel value="Yes" control={<Radio />} label="Ja" />
            <FormControlLabel value="No" control={<Radio />} label="Nein" />
          </RadioGroup>

          {/* email_confirmed */}
          <Typography>E-Mail bestätigt?</Typography>
          <RadioGroup
            row
            name="email_confirmed"
            value={formData.email_confirmed}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Ja" />
            <FormControlLabel value="No" control={<Radio />} label="Nein" />
          </RadioGroup>

          <Box sx={{ mt: 3 }}>
            <Button variant="contained" color="primary" type="submit" sx={{ mr: 2 }}>
              Speichern
            </Button>
            <Button variant="outlined" onClick={() => navigate('/admin-dashboard')}>
              Abbrechen
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Button variant="contained" sx={{ mr: 2 }} onClick={goToPayments}>
            Zahlungen ansehen
          </Button>
          <Button variant="outlined" sx={{ mr: 2 }} onClick={goToApplications}>
            Bewerbungen ansehen
          </Button>
          <Button variant="outlined" onClick={goToPreferences}>
            Preferences ansehen
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default UserEdit;
