import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  List,
  ListItem,
  ListItemText,
  TextField, 
  Paper 
} from '@mui/material';
import api from '../sharedComponents/api';
import Sidebar from './Sidebar';

// Mögliche Paketoptionen mit Voreinstellungen
const packageOptions = {
  notification_30: { listing_count: 30, price: 7.5 },
  auto_apply_30: { listing_count: 30, price: 13.5 },
  notification_50: { listing_count: 50, price: 10.0 },
  auto_apply_50: { listing_count: 50, price: 15.0 },
  notification: { listing_count: 0, price: 0 },  // Erlaubt manuelle Eingabe
  auto_apply: { listing_count: 0, price: 0 }    // Erlaubt manuelle Eingabe
};

const AdminPayments = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState({
    package_type: 'notification',
    listing_count: 0,
    amount: 0
  });
  const [isCustomizable, setIsCustomizable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPaymentId, setEditingPaymentId] = useState(null);

  // Lokale Daten für Benachrichtigungs- oder Auto-Apply-Guthaben
  const [userBalance, setUserBalance] = useState({
    notification_balance: 0,
    auto_apply_balance: 0
  });

  // Benutzerdetails laden
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await api.get(`/admin/users/${id}`);
      setUserBalance({
        notification_balance: response.data.notification_balance,
        auto_apply_balance: response.data.auto_apply_balance
      });
    } catch (error) {
      console.error("Fehler beim Laden der Benutzerdetails:", error);
    }
  }, [id]);

  // Zahlungen laden
  const fetchPayments = useCallback(async () => {
    try {
      const response = await api.get(`/admin/users/${id}/payments`);
      setPayments(response.data);
    } catch (error) {
      console.error("Fehler beim Laden der Zahlungen:", error);
    }
  }, [id]);

  // Beim ersten Rendern
  useEffect(() => {
    fetchUserDetails();
    fetchPayments();
  }, [fetchUserDetails, fetchPayments]);

  // Paket-Auswahl
  const handlePackageSelect = (e) => {
    const packageType = e.target.value;
    if (packageType in packageOptions) {
      const selectedPackage = packageOptions[packageType];
      setNewPayment({
        package_type: packageType,
        listing_count: selectedPackage.listing_count,
        amount: selectedPackage.price
      });
      // Bei 'notification' und 'auto_apply' -> manuelle Eingaben
      setIsCustomizable(packageType === 'notification' || packageType === 'auto_apply');
    }
  };

  // Zahlung anlegen oder bearbeiten
  const handleCreateOrUpdatePayment = async () => {
    try {
      const payload = {
        ...newPayment,
        listing_count: parseInt(newPayment.listing_count, 10),
        amount: parseFloat(newPayment.amount)
      };

      if (isEditing) {
        // Zahlung aktualisieren
        await api.put(`/admin/users/${id}/payments/${editingPaymentId}`, payload);
        setIsEditing(false);
        setEditingPaymentId(null);
      } else {
        // Neue Zahlung anlegen
        await api.post(`/admin/users/${id}/payments`, payload);
      }

      // Formular zurücksetzen
      setNewPayment({ package_type: 'notification', listing_count: 0, amount: 0 });
      fetchPayments();
    } catch (error) {
      console.error(
        isEditing
          ? "Fehler beim Aktualisieren der Zahlung:"
          : "Fehler beim Anlegen einer Zahlung:",
        error
      );
    }
  };

  // Bearbeiten starten
  const handleEditPayment = (payment) => {
    setNewPayment({
      package_type: payment.package_type,
      listing_count: payment.listing_count,
      amount: payment.amount
    });
    setIsEditing(true);
    setEditingPaymentId(payment.id);
    setIsCustomizable(
      payment.package_type === 'notification' || payment.package_type === 'auto_apply'
    );
  };

  // Zahlung löschen
  const handleDeletePayment = async (paymentId) => {
    try {
      await api.delete(`/admin/users/${id}/payments/${paymentId}`);
      fetchPayments();
    } catch (error) {
      console.error("Fehler beim Löschen der Zahlung:", error);
    }
  };

  // Bearbeitung abbrechen
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingPaymentId(null);
    setNewPayment({ package_type: 'notification', listing_count: 0, amount: 0 });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      {/* Hauptcontainer rechts neben der Sidebar */}
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Zahlungen für Benutzer mit ID {id}
        </Typography>

        {/* Aktuelle Guthaben anzeigen */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6">Aktuelles Guthaben</Typography>
          <Typography>
            <strong>Benachrichtigungs-Guthaben:</strong> {userBalance.notification_balance}
          </Typography>
          <Typography>
            <strong>Automatisches-Bewerbungs-Guthaben:</strong> {userBalance.auto_apply_balance}
          </Typography>
        </Box>

        {/* Bisherige Zahlungen als Liste */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Bisherige Zahlungen</Typography>
          <Paper>
            <List>
              {payments.map((payment) => (
                <Box
                  key={payment.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid #ccc'
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography component="span" sx={{ color: 'info.main', fontWeight: 'bold' }}>
                          {payment.package_type.replace('_', ' ')}
                        </Typography>
                        {' - '}
                        <Typography component="span" sx={{ color: 'success.main' }}>
                          €{payment.amount}
                        </Typography>
                        {' - '}
                        <Typography component="span" sx={{ backgroundColor: '#e0e0e0', px: 1, borderRadius: 1 }}>
                          {payment.payment_status}
                        </Typography>
                      </>
                    }
                  />
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => handleEditPayment(payment)}
                    >
                      Bearbeiten
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => handleDeletePayment(payment.id)}
                    >
                      Löschen
                    </Button>
                  </Box>
                </Box>
              ))}
            </List>
          </Paper>
        </Box>

        {/* Formular zum Hinzufügen / Bearbeiten von Zahlungen */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          {isEditing ? 'Zahlung bearbeiten' : 'Neue Zahlung hinzufügen'}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography>Paket-Typ auswählen:</Typography>
          <TextField
            select
            fullWidth
            sx={{ mt: 1 }}
            value={newPayment.package_type}
            onChange={handlePackageSelect}
            SelectProps={{ native: true }}
          >
            {Object.keys(packageOptions).map((key) => (
              <option key={key} value={key}>
                {key.replace('_', ' ')}
              </option>
            ))}
          </TextField>
        </Box>

        {isCustomizable && (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography>Listing-Anzahl</Typography>
              <TextField
                type="number"
                fullWidth
                sx={{ mt: 1 }}
                value={newPayment.listing_count}
                onChange={(e) =>
                  setNewPayment({
                    ...newPayment,
                    listing_count: parseInt(e.target.value, 10) || 0
                  })
                }
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>Betrag (EUR)</Typography>
              <TextField
                type="number"
                fullWidth
                sx={{ mt: 1 }}
                value={newPayment.amount}
                onChange={(e) =>
                  setNewPayment({
                    ...newPayment,
                    amount: parseFloat(e.target.value) || 0
                  })
                }
              />
            </Box>
          </>
        )}

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleCreateOrUpdatePayment}
            sx={{ mr: 2 }}
          >
            {isEditing ? 'Änderungen speichern' : 'Zahlung hinzufügen'}
          </Button>
          {isEditing && (
            <Button variant="outlined" color="secondary" onClick={handleCancelEdit}>
              Abbrechen
            </Button>
          )}
        </Box>

        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            onClick={() => navigate(`/admin/user/${id}/edit`)}
          >
            Zurück zum Nutzerprofil
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminPayments;
