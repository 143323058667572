import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  CircularProgress,
  Alert,
  Box
} from '@mui/material';
import api from '../sharedComponents/api';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import Sidebar from './Sidebar';

// Notwendig, damit ChartJS mit Bar/Pie funktioniert
ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend, BarElement);

const CustomerAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await api.get('/admin/analytics');
        console.log('Geladene Statistikdaten:', response.data);
        setAnalytics(response.data);
      } catch (err) {
        console.error('Fehler beim Laden der Statistikdaten:', err);
        setError('Fehler beim Laden der Daten.');
      } finally {
        setLoading(false);
      }
    };
    fetchAnalytics();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <CircularProgress />
          <Typography>Wird geladen ...</Typography>
        </Container>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Alert severity="error">{error}</Alert>
        </Container>
      </Box>
    );
  }

  if (!analytics || Object.keys(analytics).length === 0) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography>Keine Daten verfügbar</Typography>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Statistiken (Übersicht)
        </Typography>

        {/* Debug-Ausgabe der Daten (optional) */}
        {console.log('Statistik-Daten:', analytics)}

        {/* Zusammenfassende Werte */}
        <Typography>Anzahl Admins: {analytics.total_admins}</Typography>
        <Typography>Anzahl normaler Benutzer: {analytics.total_non_admins}</Typography>
        <Typography>Gesamtzahl aller Benutzer: {analytics.total_customers}</Typography>
        <Typography>Gesamt-Bewerbungen: {analytics.total_applications}</Typography>
        <Typography>Durchschnittliche Listings pro Website: {analytics.average_listings_per_website}</Typography>

        {/* Tortendiagramm: Admins vs. Benutzer */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Aufteilung Admins / Benutzer
        </Typography>
        <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto', height: '400px' }}>
          <Pie
            data={{
              labels: ['Admins', 'Benutzer'],
              datasets: [
                {
                  data: [analytics.total_admins || 0, analytics.total_non_admins || 0],
                  backgroundColor: ['#FF6384', '#36A2EB'],
                },
              ],
            }}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </Box>

        {/* Balkendiagramm: Listings pro Website */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Listings pro Website
        </Typography>
        <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto', height: '400px' }}>
          <Bar
            data={{
              labels: Object.keys(analytics.listings_by_website || {}),
              datasets: [
                {
                  label: 'Listings pro Website',
                  data: Object.values(analytics.listings_by_website || {}),
                  backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FFA726',
                    '#8D6E63',
                  ],
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: true,
                  position: 'top',
                },
              },
            }}
          />
        </Box>

        {/* Balkendiagramm: Bewerbungen pro Website */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Bewerbungen pro Website
        </Typography>
        <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto', height: '400px' }}>
          <Bar
            data={{
              labels: Object.keys(analytics.applications_by_website || {}),
              datasets: [
                {
                  label: 'Bewerbungen pro Website',
                  data: Object.values(analytics.applications_by_website || {}),
                  backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FFA726',
                    '#8D6E63',
                  ],
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default CustomerAnalytics;
