import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button
} from '@mui/material';
import api from '../sharedComponents/api';
import Sidebar from './Sidebar';

const AdminWebsites = () => {
  const [websites, setWebsites] = useState([]);
  const [editingId, setEditingId] = useState(null); // Welche Website wird gerade bearbeitet?
  const [newLogo, setNewLogo] = useState('');       // Eingabefeld für die neue Logo-URL
  const [error, setError] = useState('');
  
  const placeholderLogo = 'https://via.placeholder.com/50';

  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const response = await api.get('/admin/websites');
        if (Array.isArray(response.data)) {
          setWebsites(response.data);
        } else {
          setWebsites([]);
        }
      } catch (err) {
        console.error('Fehler beim Laden der Websites:', err);
        setError('Fehler beim Laden der Websites');
      }
    };

    fetchWebsites();
  }, []);

  const handleEditClick = (websiteId, currentLogo) => {
    setEditingId(websiteId);
    setNewLogo(currentLogo || '');
  };

  const handleSaveClick = async (websiteId) => {
    try {
      await api.put(`/admin/websites/${websiteId}`, { logo: newLogo });
      // Lokale Aktualisierung, ohne nochmalige API-Anfrage
      setWebsites((prev) =>
        prev.map((w) => (w.id === websiteId ? { ...w, logo: newLogo } : w))
      );
      setEditingId(null); // Aus Bearbeitungsmodus gehen
    } catch (err) {
      console.error('Fehler beim Speichern des neuen Logos:', err);
      setError('Fehler beim Speichern des neuen Logos');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Websites verwalten
        </Typography>

        {error && (
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        )}

        {websites.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Logo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {websites.map((website) => (
                  <TableRow key={website.id}>
                    <TableCell>{website.id}</TableCell>
                    <TableCell>
                      {editingId === website.id ? (
                        <TextField
                          value={newLogo}
                          onChange={(e) => setNewLogo(e.target.value)}
                          label="Logo-URL eingeben"
                          fullWidth
                        />
                      ) : (
                        <img
                          src={website.logo || placeholderLogo}
                          alt={`${website.name} logo`}
                          style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                            borderRadius: '8px'
                          }}
                          onError={(e) => {
                            e.target.src = placeholderLogo;
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>{website.name}</TableCell>
                    <TableCell>
                      <a
                        href={website.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {website.url}
                      </a>
                    </TableCell>
                    <TableCell>
                      {editingId === website.id ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveClick(website.id)}
                          sx={{ mr: 1 }}
                        >
                          Speichern
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleEditClick(website.id, website.logo)}
                          sx={{ mr: 1 }}
                        >
                          Logo ändern
                        </Button>
                      )}
                      {editingId === website.id && (
                        <Button
                          variant="text"
                          onClick={() => setEditingId(null)}
                        >
                          Abbrechen
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            align="center"
            sx={{ mt: 2 }}
          >
            Keine Websites verfügbar
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default AdminWebsites;
