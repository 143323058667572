import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import api from '../sharedComponents/api';
import Sidebar from './Sidebar';

function SchemaView() {
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);  // Zeigt Fehlermeldung, falls das Laden fehlschlägt
  const [loading, setLoading] = useState(true); // Für Ladeanzeige

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const response = await api.get('/admin/schema', {
          responseType: 'blob', // Wir erwarten ein Bild (Blob)
        });

        if (response.status === 200) {
          const blob = response.data;
          const blobUrl = URL.createObjectURL(blob);
          console.log('Erzeugte Blob-URL:', blobUrl);
          setImageUrl(blobUrl);
        } else {
          console.error('Fehler beim Abrufen des Schemas:', response.status);
          setError('Fehler beim Laden des Schemas');
        }
      } catch (err) {
        console.error('Fehler beim Laden des Schemas:', err);
        setError('Fehler beim Laden des Schemas');
      } finally {
        setLoading(false);
      }
    };

    fetchSchema();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Datenbankschema
        </Typography>

        {/* Ladeanzeige */}
        {loading && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Lade Schema ...
          </Typography>
        )}

        {/* Fehlermeldung oder Bildanzeige */}
        {error ? (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt="Datenbankschema"
            style={{ width: '100%', borderRadius: '8px' }}
          />
        ) : null}
      </Container>
    </Box>
  );
}

export default SchemaView;
