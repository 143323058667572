import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert
} from '@mui/material';
import api from '../sharedComponents/api';
import Sidebar from './Sidebar';

const ServiceStatus = () => {
  const [services, setServices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchServiceStatus = async () => {
      try {
        const response = await api.get('/services/status');
        setServices(response.data);
      } catch (err) {
        console.error('Fehler beim Laden des Dienststatus:', err);
        setError('Fehler beim Laden des Dienststatus');
      } finally {
        setLoading(false);
      }
    };
    fetchServiceStatus();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container maxWidth="lg" sx={{ ml: '260px', mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dienststatus
        </Typography>

        <Paper sx={{ p: 2 }}>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <List>
              {Object.entries(services).map(([name, status]) => {
                // Deutschsprachige Anzeige
                const statusText = 
                  status === 'healthy'
                    ? 'Betrieb'
                    : status === 'running'
                    ? 'In Betrieb'
                    : status === 'not found'
                    ? 'Nicht gefunden'
                    : status === 'error'
                    ? 'Fehler'
                    : 'Außer Betrieb';

                return (
                  <ListItem key={name}>
                    <ListItemText
                      primary={name}
                      secondary={statusText}
                      sx={{
                        color:
                          status === 'healthy' || status === 'running'
                            ? 'green'
                            : 'red'
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default ServiceStatus;
