import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Divider,
  useMediaQuery,
} from '@mui/material';
import {
  Home as HomeIcon,
  ListAlt as ListAltIcon,
  Schema as SchemaIcon,
  Web as WebIcon,
  Analytics as AnalyticsIcon,
  Discount as DiscountIcon,
  Menu as MenuIcon,
  MonitorHeart as MonitorHeartIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function Sidebar() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={toggleSidebar}
        sx={{
          width: open ? 240 : (isMobile ? 0 : 60),
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? 240 : (isMobile ? 0 : 60),
            transition: 'width 0.3s',
            overflowX: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: open ? 'space-between' : 'center',
            alignItems: 'center',
            padding: '0 16px',
            height: 64,
            bgcolor: 'primary.main',
            color: 'white',
          }}
        >
          {/* Titel nur einblenden, wenn geöffnet und nicht mobil */}
          {open && !isMobile && (
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Admin-Bereich
            </Box>
          )}
          <IconButton onClick={toggleSidebar} sx={{ color: 'white' }}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Divider />

        <List>
          {/* Benutzerliste */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/admin-dashboard')}>
              <ListItemIcon>
                <HomeIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Benutzerliste" />}
            </ListItemButton>
          </ListItem>

          {/* Listings */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/admin/listings')}>
              <ListItemIcon>
                <ListAltIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Listing-Liste" />}
            </ListItemButton>
          </ListItem>

          {/* Schema */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/admin/schema')}>
              <ListItemIcon>
                <SchemaIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Datenbankschema" />}
            </ListItemButton>
          </ListItem>

          {/* Websites */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/admin/websites')}>
              <ListItemIcon>
                <WebIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Websites verwalten" />}
            </ListItemButton>
          </ListItem>

          {/* Rabatte */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/admin/discounts')}>
              <ListItemIcon>
                <DiscountIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Rabatte verwalten" />}
            </ListItemButton>
          </ListItem>

          {/* Analytics */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/admin-analytics')}>
              <ListItemIcon>
                <AnalyticsIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Kunden-Statistiken" />}
            </ListItemButton>
          </ListItem>

          {/* Dienststatus */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/service-status')}>
              <ListItemIcon>
                <MonitorHeartIcon color="primary" />
              </ListItemIcon>
              {open && <ListItemText primary="Dienststatus" />}
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      {/* Haupt-Inhalt rechts daneben */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {/* Hier könnte der restliche Content gerendert werden */}
      </Box>
    </Box>
  );
}

export default Sidebar;
