import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Sidebar from './Sidebar';
import api from '../sharedComponents/api';

/*
  Beispielschema für `listings`:
    [
      {
        id,
        price,
        size,
        rooms,
        address,
        wbs,
        is_expired,
        website_name,
        posted_date,
        url,
        applied_count
      },
      ...
    ]
*/

const AdminListings = () => {
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  
  // Anzahl der Listings vor / nach Filter
  const [totalListings, setTotalListings] = useState(0);

  // Filteroptionen
  // wbsFilter: "all" | "onlyWbs" | "withoutWbs"
  // expiredFilter: "all" | "onlyExpired" | "onlyNotExpired"
  // websiteFilter: "all" | <website_name>
  const [filters, setFilters] = useState({
    wbsFilter: 'all',
    expiredFilter: 'all',
    minPrice: '',
    maxPrice: '',
    minRooms: '',
    maxRooms: '',
    websiteFilter: 'all'
  });

  // Alle möglichen Websites (zum Befüllen der Filter-Select)
  const [allWebsites, setAllWebsites] = useState([]);

  // Bewerbung per Dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [dummyData, setDummyData] = useState({
    name: '',
    email: '',
    first_name: '',
    last_name: '',
    birth_date: '',
    gender: '',
    phone_number: '',
    number_of_persons: '',
    message: '',
    ishandycap: false,
    haswbs: false,
    wbs_expire_date: '',
    wbs_number_of_rooms: '',
    wbs_type: ''
  });

  // Fehlermeldungen
  const [error, setError] = useState('');

  // Beim Laden: Listings + Websites holen
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get('/admin/listings');
        if (Array.isArray(response.data)) {
          setListings(response.data);
          setTotalListings(response.data.length);
          setFilteredListings(response.data);

          // Alle Websites extrahieren
          const uniqueWebsites = Array.from(
            new Set(response.data.map((item) => item.website_name))
          );
          setAllWebsites(uniqueWebsites);

        } else {
          setError('Unerwartetes Datenformat erhalten.');
        }
      } catch (err) {
        console.error('Fehler beim Laden der Listings:', err);
        setError('Fehler beim Laden der Listings.');
      }
    }
    fetchData();
  }, []);

  // Filter anwenden
  const applyFilter = () => {
    let temp = [...listings];
    const {
      wbsFilter,
      expiredFilter,
      minPrice,
      maxPrice,
      minRooms,
      maxRooms,
      websiteFilter
    } = filters;

    // WBS-Filter
    if (wbsFilter === 'onlyWbs') {
      temp = temp.filter((item) => item.wbs === true);
    } else if (wbsFilter === 'withoutWbs') {
      temp = temp.filter((item) => item.wbs === false);
    }

    // Expired-Filter
    if (expiredFilter === 'onlyExpired') {
      temp = temp.filter((item) => item.is_expired === true);
    } else if (expiredFilter === 'onlyNotExpired') {
      temp = temp.filter((item) => item.is_expired === false);
    }

    // Website-Filter
    if (websiteFilter !== 'all') {
      temp = temp.filter((item) => item.website_name === websiteFilter);
    }

    // Preisfilter
    if (minPrice) {
      const minVal = parseFloat(minPrice);
      if (!isNaN(minVal)) {
        temp = temp.filter((item) => item.price >= minVal);
      }
    }
    if (maxPrice) {
      const maxVal = parseFloat(maxPrice);
      if (!isNaN(maxVal)) {
        temp = temp.filter((item) => item.price <= maxVal);
      }
    }

    // Raum-Filter
    if (minRooms) {
      const minR = parseInt(minRooms, 10);
      if (!isNaN(minR)) {
        temp = temp.filter((item) => item.rooms >= minR);
      }
    }
    if (maxRooms) {
      const maxR = parseInt(maxRooms, 10);
      if (!isNaN(maxR)) {
        temp = temp.filter((item) => item.rooms <= maxR);
      }
    }

    setFilteredListings(temp);
  };

  // Filter-Input ändern
  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  // Bewerben
  const handleApplyClick = (listing) => {
    setSelectedListing(listing);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedListing(null);
    // Dummy-Daten zurücksetzen
    setDummyData({
      name: '',
      email: '',
      first_name: '',
      last_name: '',
      birth_date: '',
      gender: '',
      phone_number: '',
      number_of_persons: '',
      message: '',
      ishandycap: false,
      haswbs: false,
      wbs_expire_date: '',
      wbs_number_of_rooms: '',
      wbs_type: ''
    });
  };

  // Form-Felder für Dummy-Daten
  const handleDummyDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setDummyData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setDummyData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Abschicken
  const handleApplySubmit = async () => {
    if (!selectedListing) return;
    try {
      const response = await api.post('/admin/apply', {
        listing_url: selectedListing.url,
        ...dummyData
      });
      if (response.status === 200) {
        alert('Bewerbung erfolgreich abgeschickt.');
      } else {
        console.error('Fehler bei der Bewerbung:', response.data);
        setError('Fehler bei der Bewerbung.');
      }
    } catch (err) {
      console.error('Fehler bei der Bewerbung:', err);
      setError('Fehler bei der Bewerbung.');
    } finally {
      handleDialogClose();
    }
  };

  // Listing-URL öffnen
  const handleOpenListing = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box display="flex">
      <Sidebar />
      <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Admin-Übersicht Listings
        </Typography>
        
        {error && <Typography color="error">{error}</Typography>}

        {/* Vor/Nach Filter */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">
            Gesamtanzahl (vor Filter): {totalListings}
          </Typography>
          <Typography variant="body1">
            Gefilterte Anzahl: {filteredListings.length}
          </Typography>
        </Box>

        {/* Filter-Bereich */}
        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Filter
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {/* WBS-Filter */}
            <Box>
              <Typography variant="body2">WBS</Typography>
              <Select
                value={filters.wbsFilter}
                onChange={(e) => handleFilterChange('wbsFilter', e.target.value)}
                sx={{ width: 160 }}
              >
                <MenuItem value="all">Beides</MenuItem>
                <MenuItem value="onlyWbs">Nur mit WBS</MenuItem>
                <MenuItem value="withoutWbs">Ohne WBS</MenuItem>
              </Select>
            </Box>

            {/* Expired-Filter */}
            <Box>
              <Typography variant="body2">Status</Typography>
              <Select
                value={filters.expiredFilter}
                onChange={(e) => handleFilterChange('expiredFilter', e.target.value)}
                sx={{ width: 160 }}
              >
                <MenuItem value="all">Alle</MenuItem>
                <MenuItem value="onlyExpired">Abgelaufen</MenuItem>
                <MenuItem value="onlyNotExpired">Nicht abgelaufen</MenuItem>
              </Select>
            </Box>

            {/* Website-Filter */}
            <Box>
              <Typography variant="body2">Website</Typography>
              <Select
                value={filters.websiteFilter}
                onChange={(e) => handleFilterChange('websiteFilter', e.target.value)}
                sx={{ width: 180 }}
              >
                <MenuItem value="all">Alle</MenuItem>
                {/** dynamische Liste aller Websites */}
                {allWebsites.map((site) => (
                  <MenuItem key={site} value={site}>
                    {site}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* Preis */}
            <Box>
              <TextField
                label="Mindestpreis"
                type="number"
                variant="outlined"
                size="small"
                value={filters.minPrice}
                onChange={(e) => handleFilterChange('minPrice', e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                label="Maximalpreis"
                type="number"
                variant="outlined"
                size="small"
                value={filters.maxPrice}
                onChange={(e) => handleFilterChange('maxPrice', e.target.value)}
              />
            </Box>

            {/* Räume */}
            <Box>
              <TextField
                label="Mindest-Räume"
                type="number"
                variant="outlined"
                size="small"
                value={filters.minRooms}
                onChange={(e) => handleFilterChange('minRooms', e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                label="Maximal-Räume"
                type="number"
                variant="outlined"
                size="small"
                value={filters.maxRooms}
                onChange={(e) => handleFilterChange('maxRooms', e.target.value)}
              />
            </Box>

            <Box>
              <Button variant="contained" onClick={applyFilter}>
                Filter anwenden
              </Button>
            </Box>
          </Box>
        </Paper>

        {/* Tabelle */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Preis</TableCell>
                <TableCell>Größe</TableCell>
                <TableCell>Räume</TableCell>
                <TableCell>Adresse</TableCell>
                <TableCell>WBS</TableCell>
                <TableCell>Abgelaufen?</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Beworben</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredListings.length > 0 ? (
                filteredListings.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.size}</TableCell>
                    <TableCell>{row.rooms}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.wbs ? 'Ja' : 'Nein'}</TableCell>
                    <TableCell>{row.is_expired ? 'Ja' : 'Nein'}</TableCell>
                    <TableCell>{row.website_name}</TableCell>
                    <TableCell>
                      {row.posted_date
                        ? new Date(row.posted_date).toLocaleDateString()
                        : ''}
                    </TableCell>
                    <TableCell>{row.applied_count || 0}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        sx={{ mr: 1 }}
                        onClick={() => handleOpenListing(row.url)}
                      >
                        Öffnen
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleApplyClick(row)}
                      >
                        Bewerben
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11}>
                    <Typography align="center">
                      Keine Einträge vorhanden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Bewerbungs-Dialog */}
        <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth>
          <DialogTitle>Manuelle Bewerbung</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              name="name"
              value={dummyData.name}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="E-Mail"
              name="email"
              value={dummyData.email}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Vorname"
              name="first_name"
              value={dummyData.first_name}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Nachname"
              name="last_name"
              value={dummyData.last_name}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Geburtsdatum"
              type="date"
              name="birth_date"
              value={dummyData.birth_date}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Geschlecht"
              name="gender"
              value={dummyData.gender}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Telefonnummer"
              name="phone_number"
              value={dummyData.phone_number}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Personenanzahl"
              name="number_of_persons"
              value={dummyData.number_of_persons}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Nachricht"
              name="message"
              value={dummyData.message}
              onChange={handleDummyDataChange}
              fullWidth
              multiline
              rows={2}
              margin="dense"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dummyData.ishandycap}
                  onChange={handleDummyDataChange}
                  name="ishandycap"
                  color="primary"
                />
              }
              label="Handicap?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dummyData.haswbs}
                  onChange={handleDummyDataChange}
                  name="haswbs"
                  color="primary"
                />
              }
              label="WBS?"
            />
            <TextField
              label="WBS gültig bis"
              type="date"
              name="wbs_expire_date"
              value={dummyData.wbs_expire_date}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="WBS Zimmeranzahl"
              name="wbs_number_of_rooms"
              value={dummyData.wbs_number_of_rooms}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="WBS-Typ"
              name="wbs_type"
              value={dummyData.wbs_type}
              onChange={handleDummyDataChange}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Abbrechen</Button>
            <Button onClick={handleApplySubmit} variant="contained" color="primary">
              Bewerben
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AdminListings;
