import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../sharedComponents/api';
import '../App.css';
import Sidebar from './Sidebar';
const DiscountAdminPage = () => {
  const [discounts, setDiscounts] = useState([]);
  const [newCode, setNewCode] = useState('');
  const [newType, setNewType] = useState('percentage'); // 'percentage' or 'fixed'
  const [newValue, setNewValue] = useState('');
  const [newExpiresAt, setNewExpiresAt] = useState(''); // New expiration date field
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await api.get('/admin/discounts');
        setDiscounts(response.data);
      } catch (error) {
        console.error('Error fetching discounts:', error);
      }
    };

    fetchDiscounts();
  }, []);

  const handleAddDiscount = async () => {
    if (!newCode || !newValue || isNaN(newValue)) {
      alert('Please provide valid inputs for the discount code and value.');
      return;
    }
    setLoading(true);
    try {
      const formattedExpiresAt = newExpiresAt ? new Date(newExpiresAt).toISOString() : null;
      const response = await api.post('/admin/discounts', {
        code: newCode,
        type: newType,
        value: parseFloat(newValue),
        expires_at: formattedExpiresAt,  // Format the expiration date properly
      });
      setDiscounts([...discounts, response.data.discount]);
      setNewCode('');
      setNewType('percentage');
      setNewValue('');
      setNewExpiresAt('');
    } catch (error) {
      console.error('Error adding discount:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDiscount = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this discount?');
    if (confirmDelete) {
      try {
        await api.delete(`/admin/discounts/${id}`);
        setDiscounts(discounts.filter((discount) => discount.id !== id));
      } catch (error) {
        console.error('Error deleting discount:', error);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
    <Sidebar/>
    <Container maxWidth="md" className="discount-admin-container">
      <Typography variant="h4" gutterBottom>
        Manage Discounts
      </Typography>
      <div className="add-discount-form">
        <TextField
          label="Discount Code"
          value={newCode}
          onChange={(e) => setNewCode(e.target.value)}
          required
        />
        <TextField
          label="Type"
          value={newType}
          onChange={(e) => setNewType(e.target.value)}
          select
          SelectProps={{ native: true }}
          required
        >
          <option value="percentage">Percentage</option>
          <option value="fixed">Fixed</option>
        </TextField>
        <TextField
          label="Value"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          type="number"
          required
        />
        <TextField
          label="Expiration Date"
          type="date"
          value={newExpiresAt}
          onChange={(e) => setNewExpiresAt(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddDiscount}
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add Discount'}
        </Button>
      </div>
      <TableContainer component={Paper} className="discount-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Expiration Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts.map((discount) => (
              <TableRow key={discount.id}>
                <TableCell>{discount.code}</TableCell>
                <TableCell>{discount.type}</TableCell>
                <TableCell>{discount.value}</TableCell>
                <TableCell>{new Date(discount.created_at).toLocaleString()}</TableCell>
                <TableCell>{discount.expires_at ? new Date(discount.expires_at).toLocaleDateString() : 'None'}</TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteDiscount(discount.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
    </Box>
  );
};

export default DiscountAdminPage;
