import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Button
} from '@mui/material';
import api from '../sharedComponents/api';
import Sidebar from './Sidebar';

// Liste aller Bezirke
const bezirke = [
  'Charlottenburg-Wilmersdorf',
  'Friedrichshain-Kreuzberg',
  'Lichtenberg',
  'Marzahn-Hellersdorf',
  'Mitte',
  'Neukölln',
  'Pankow',
  'Reinickendorf',
  'Spandau',
  'Steglitz-Zehlendorf',
  'Tempelhof-Schöneberg',
  'Treptow-Köpenick'
];

const AdminPreferences = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    min_rooms: '',
    max_rooms: '',
    min_size: '',
    max_size: '',
    min_price: '',
    max_price: '',
    haswbs: 'bothwbs', // Standardwert
    location: []
  });

  const [errors, setErrors] = useState({});
  const [selectAll, setSelectAll] = useState(false); // Steuert "Alle auswählen"

  // Beim Laden: vorhandene Präferenzen abrufen
  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await api.get(`/admin/users/${id}/preferences`);
        if (response.data) {
          setFormData({
            min_rooms: response.data.min_rooms || '',
            max_rooms: response.data.max_rooms || '',
            min_size: response.data.min_size || '',
            max_size: response.data.max_size || '',
            min_price: response.data.min_price || '',
            max_price: response.data.max_price || '',
            haswbs: response.data.haswbs
              ? response.data.haswbs === true
                ? 'true'
                : 'false'
              : 'bothwbs',
            location: response.data.location || []
          });
          // Prüfen, ob alle Bezirke bereits ausgewählt sind
          if (response.data.location && response.data.location.length === bezirke.length) {
            setSelectAll(true);
          }
        }
      } catch (error) {
        console.error('Fehler beim Laden der Präferenzen:', error);
      }
    };

    fetchPreferences();
  }, [id]);

  // Allgemeiner Input-Handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  // Checkbox-Handler für Bezirke
  const handleLocationChange = (bezirk) => {
    let neueLocations = [...formData.location];
    if (neueLocations.includes(bezirk)) {
      neueLocations = neueLocations.filter((loc) => loc !== bezirk);
    } else {
      neueLocations.push(bezirk);
    }
    setFormData((prev) => ({ ...prev, location: neueLocations }));
    setSelectAll(neueLocations.length === bezirke.length);
  };

  // "Alle auswählen" toggeln
  const handleSelectAll = () => {
    if (!selectAll) {
      // Falls noch nicht alle ausgewählt, nun alle auswählen
      setFormData((prev) => ({ ...prev, location: bezirke }));
    } else {
      // Sonst alles abwählen
      setFormData((prev) => ({ ...prev, location: [] }));
    }
    setSelectAll(!selectAll);
  };

  // Validierung
  const validateForm = () => {
    const newErrors = {};
    if (!formData.min_rooms) newErrors.min_rooms = 'Bitte die minimale Zimmeranzahl eingeben.';
    if (!formData.max_rooms) newErrors.max_rooms = 'Bitte die maximale Zimmeranzahl eingeben.';
    if (!formData.min_size) newErrors.min_size = 'Bitte die minimale Größe eingeben.';
    if (!formData.max_size) newErrors.max_size = 'Bitte die maximale Größe eingeben.';
    if (!formData.min_price) newErrors.min_price = 'Bitte den minimalen Preis eingeben.';
    if (!formData.max_price) newErrors.max_price = 'Bitte den maximalen Preis eingeben.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Absenden
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await api.put(`/admin/users/${id}/preferences`, formData);
      alert('Präferenzen wurden erfolgreich gespeichert.');
      navigate('/admin-dashboard');
    } catch (error) {
      console.error('Fehler beim Speichern der Präferenzen:', error);
      alert('Speichern der Präferenzen ist fehlgeschlagen.');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />

      <Container maxWidth="lg" sx={{ ml: '260px', mt: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Präferenzen des Nutzers bearbeiten
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          {/* Minimaler Zimmer */}
          <TextField
            label="Minimale Zimmeranzahl"
            type="number"
            fullWidth
            margin="normal"
            name="min_rooms"
            value={formData.min_rooms}
            onChange={handleInputChange}
            error={!!errors.min_rooms}
            helperText={errors.min_rooms}
          />

          {/* Maximaler Zimmer */}
          <TextField
            label="Maximale Zimmeranzahl"
            type="number"
            fullWidth
            margin="normal"
            name="max_rooms"
            value={formData.max_rooms}
            onChange={handleInputChange}
            error={!!errors.max_rooms}
            helperText={errors.max_rooms}
          />

          {/* Minimale Größe */}
          <TextField
            label="Minimale Größe (m²)"
            type="number"
            fullWidth
            margin="normal"
            name="min_size"
            value={formData.min_size}
            onChange={handleInputChange}
            error={!!errors.min_size}
            helperText={errors.min_size}
          />

          {/* Maximale Größe */}
          <TextField
            label="Maximale Größe (m²)"
            type="number"
            fullWidth
            margin="normal"
            name="max_size"
            value={formData.max_size}
            onChange={handleInputChange}
            error={!!errors.max_size}
            helperText={errors.max_size}
          />

          {/* Minimaler Preis */}
          <TextField
            label="Minimaler Preis (€)"
            type="number"
            fullWidth
            margin="normal"
            name="min_price"
            value={formData.min_price}
            onChange={handleInputChange}
            error={!!errors.min_price}
            helperText={errors.min_price}
          />

          {/* Maximaler Preis */}
          <TextField
            label="Maximaler Preis (€)"
            type="number"
            fullWidth
            margin="normal"
            name="max_price"
            value={formData.max_price}
            onChange={handleInputChange}
            error={!!errors.max_price}
            helperText={errors.max_price}
          />

          {/* Standort-Checkboxen */}
          <Typography variant="h6" sx={{ mt: 3 }}>
            Standorte
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            }
            label="Alle auswählen"
            sx={{ mb: 1 }}
          />

          {/* Dynamische Auflistung aller Bezirke */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {bezirke.map((bezirk) => {
              const checked = formData.location.includes(bezirk);
              return (
                <FormControlLabel
                  key={bezirk}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => handleLocationChange(bezirk)}
                    />
                  }
                  label={bezirk}
                />
              );
            })}
          </Box>

          {/* WBS-Auswahl per RadioButtons */}
          <Typography variant="h6" sx={{ mt: 3 }}>
            Wohnberechtigungsschein (WBS)
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <FormControlLabel
              control={
                <Radio
                  checked={formData.haswbs === 'bothwbs'}
                  onChange={handleInputChange}
                  value="bothwbs"
                  name="haswbs"
                />
              }
              label="Beides"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={formData.haswbs === 'true'}
                  onChange={handleInputChange}
                  value="true"
                  name="haswbs"
                />
              }
              label="Ja"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={formData.haswbs === 'false'}
                  onChange={handleInputChange}
                  value="false"
                  name="haswbs"
                />
              }
              label="Nein"
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <Button variant="contained" color="primary" type="submit" sx={{ mr: 2 }}>
              Speichern
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => navigate('/admin-dashboard')}>
              Abbrechen
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminPreferences;
