import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import api from '../sharedComponents/api';

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/admin/users');
        if (Array.isArray(response.data)) {
          setUsers(response.data);
          setFilteredUsers(response.data); // Standard: Alle User
        } else {
          console.error('Unerwartetes Datenformat für Benutzerdaten erhalten.');
          setUsers([]);
          setFilteredUsers([]);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzer:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    const bestaetigen = window.confirm('Möchten Sie diesen Nutzer wirklich löschen?');
    if (bestaetigen) {
      try {
        await api.delete(`/admin/users/${userId}`);
        // Anschließend lokale Liste aktualisieren
        setUsers((prev) => prev.filter((u) => u.id !== userId));
        setFilteredUsers((prev) => prev.filter((u) => u.id !== userId));
      } catch (error) {
        console.error('Fehler beim Löschen des Nutzers:', error);
      }
    }
  };

  const handleEdit = (userId) => {
    // Weiterleitung zu Edit-Seite
    navigate(`/admin/user/${userId}/edit`);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Container maxWidth="lg" sx={{ display: 'flex', mt: 4, mb: 4 }}>
        <Sidebar />
        {/* Damit Sidebar und Content nicht überlappen */}
        <Container sx={{ ml: '260px' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Admin-Bereich (Benutzerverwaltung)
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>E-Mail</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Rolle</TableCell>
                  <TableCell>Letzter Login</TableCell>
                  <TableCell align="right">Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
                  filteredUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.first_name} {user.last_name}
                      </TableCell>
                      <TableCell>
                        {user.is_admin ? 'Administrator' : 'Benutzer'}
                      </TableCell>
                      <TableCell>
                        {user.last_login === '2100-01-01'
                          ? 'Nie eingeloggt'
                          : user.last_login}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          aria-label="Bearbeiten"
                          onClick={() => handleEdit(user.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          aria-label="Löschen"
                          onClick={() => handleDelete(user.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      Keine Benutzer gefunden
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Container>
    </Box>
  );
}

export default AdminDashboard;
