import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import api from '../sharedComponents/api';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button
} from '@mui/material';

const AdminAppliedListings = () => {
  const [listings, setListings] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // ID des Nutzers aus den URL-Parametern
  const { id } = useParams();

  // Platzhalter-Bilder
  const placeholderImage = process.env.PUBLIC_URL + '/germany-map_2.png';
  const emptyStateImage = process.env.PUBLIC_URL + '/not-available.svg';

  useEffect(() => {
    const fetchAdminAppliedListings = async () => {
      try {
        const response = await api.get(`/admin/users/${id}/applications`);

        if (response.data.length === 0) {
          setError('Keine Bewerbungen für diesen Nutzer gefunden.');
        } else {
          setListings(response.data);
        }
      } catch (err) {
        console.error('Fehler beim Abrufen der Bewerbungen:', err);
        setError('Fehler beim Abrufen der Einträge.');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminAppliedListings();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Container maxWidth="lg" sx={{ ml: { sm: '240px', xs: '0' }, mt: 4, mb: 4 }}>
          <Typography variant="h6">Lade Daten ...</Typography>
          <CircularProgress sx={{ mt: 2 }} />
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Container maxWidth="lg" sx={{ ml: { sm: '240px', xs: '0' }, mt: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Beworbene Einträge
        </Typography>

        {/* Fehleranzeige */}
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {/* Leerer Zustand (wenn kein Fehler und keine Einträge) */}
        {!error && listings.length === 0 && (
          <Box
            sx={{
              textAlign: 'center',
              my: 5
            }}
          >
            <Box
              component="img"
              src={emptyStateImage}
              alt="Keine Einträge"
              sx={{ maxWidth: 300, mb: 2 }}
            />
            <Typography color="warning.main" variant="body1">
              Derzeit liegen keine beworbenen Einträge für diesen Nutzer vor.
            </Typography>
          </Box>
        )}

        {/* Ansonsten Grid-Layout für die Einträge */}
        {listings.length > 0 && (
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {listings.map((listing, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    image={listing.image_url || placeholderImage}
                    alt={listing.title || 'Angebot'}
                    sx={{ height: 180, objectFit: 'cover' }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {listing.title}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {listing.description}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Preis:</strong> {listing.price}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Größe:</strong> {listing.size} m²
                    </Typography>
                    <Typography variant="body2">
                      <strong>Zimmer:</strong> {listing.rooms}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Inseratsdatum:</strong>{' '}
                      {listing.posted_date || 'Nicht verfügbar'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Bewerbungsdatum:</strong>{' '}
                      {listing.applied_at || 'Nicht verfügbar'}
                    </Typography>
                  </CardContent>
                  <Box sx={{ p: 2 }}>
                    <Button
                      variant="text"
                      color="primary"
                      href={listing.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mehr Details
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default AdminAppliedListings;
